import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const faqItems = [
    {
      question: "How do I use this calculator?",
      answer: "Add multiple network components (switch, firewall, WAN, fibre, etc.) to represent your network or a network design. The tool will then calculate the latency of these components using vendor documentation and industry standards."
    },
    {
      question: "How accurate is this calculator?",
      answer: "This calculator provides an estimated latency based on vendor documentation and known constants (e.g., 5ns per meter of fibre). For a more accurate estimate, consider factors such as transceiver delay, speed changes, packet size, cut-through vs store-and-forward switching, and additional firewall features. A caveat is provided along with manufacturer documentation for your review using the info button on each component, most of the time the latency the vendor provides is the minimum latency."
    },
    {
      question: "Who is this tool meant for?",
      answer: "This tool is designed for network professionals who use enterprise equipment and prioritize latency. Examples include trading firms, exchanges, certain scientific applications, and high-performance computing (HPC) environments."
    },
    {
      question: "How can I accurately determine my network latency?",
      answer: "The method depends on the precision of latency you want to measure. For milliseconds, simple ICMP ping can suffice. For microseconds, more accurate traffic recording using an FPGA appliance (non-CPU bound) with precise timestamping is recommended. Measuring nanosecond latency is significantly more complex and requires specialized equipment."
    },
    {
      question: "How often is the latency data updated?",
      answer: "The latency data in this calculator was last updated in September 2024."
    },
    {
      question: "Can I add custom network components?",
      answer: "Yes, you can add custom fibre/WAN components as well as a static latency component to your network configuration. For fibre, you can specify custom lengths(Meters), and for WAN, you can input custom distances(KMs). The calculator will use standard latency values for these custom components. For other component types, you can use the static latency component to add any additional latency to your network configuration in ns,us,ms."
    },
    {
      question: "Is my data safe? Does this tool collect or store any network information?",
      answer: "This tool operates entirely in your browser and does not collect, store, or transmit any of your network information. Your network configurations are not saved once you close or refresh the browser tab."
    },
    {
      question: "Who created this tool?",
      answer: "This tool was created by Tom. You can email me at 'tom' at this domain."
    }
  ];
  

  return (
    <div className="min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8 relative">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">About Latency Lens</h1>
        
        <p className="mb-8 text-gray-700">
          Latency Lens is a tool designed to help network professionals estimate 
          and analyze network latency. It allows users to build virtual network designs and calculate 
          the total latency based on various network components.
        </p>

        <h2 className="text-2xl font-bold text-gray-900 mb-4">Frequently Asked Questions</h2>
        
        {faqItems.map((item, index) => (
          <div key={index} className="mb-6">
            <h3 className="text-lg font-semibold text-gray-800 mb-2">{item.question}</h3>
            <p className="text-gray-700">{item.answer}</p>
          </div>
        ))}
      </div>

      <div className="fixed bottom-4 right-4">
        <Link 
          to="/" 
          className="bg-blue-500 text-white px-4 py-2 rounded-full shadow-lg hover:bg-blue-600 transition-colors duration-300"
          onClick={() => window.scrollTo(0, 0)}
        >
          Back to Calculator
        </Link>
      </div>
    </div>
  );
};

export default About;
