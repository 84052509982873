import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NetworkLatencyCalculator from './NetworkLatencyCalculator';
import About from './About';
//import SwitchLatencySimulator from './SwitchLatencySimulator';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<NetworkLatencyCalculator />} />
        <Route path="/about" element={<About />} />
      </Routes>
    </Router>
  );
}

export default App;