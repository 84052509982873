import React, { useState, useRef, useEffect, useMemo } from 'react';
import { PlusCircle, Trash2, ChevronDown, Info, Server, Network, Shield, Globe, Edit2, ChevronUp, GripVertical } from 'lucide-react';
import { Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement, Filler } from 'chart.js';
import networkData from './network-data.json';
import { Link } from 'react-router-dom';
import { lighten, darken } from 'polished';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement, Filler);

const calculateFibreLatency = (lengthInMeters) => {
  // Example calculation: 5ns per meter
  return lengthInMeters * 5;
};

const calculateWANLatency = (distanceInKm) => {
  // Example calculation: 5000ns per kilometer
  return distanceInKm * 5000;
};

const LatencyBreakdownChart = ({ elements }) => {
  const data = {
    // Update this line to include both custom name and element name
    labels: elements.map(el => el.customName ? `${el.customName} (${el.name})` : el.name),
    datasets: [
      {
        label: 'Latency (ns)',
        data: elements.map(el => el.latency),
        backgroundColor: elements.map(el => {
          switch (el.type) {
            case 'switch': return 'rgba(54, 162, 235, 0.6)';
            case 'fibre': return 'rgba(75, 192, 192, 0.6)';
            case 'firewall': return 'rgba(255, 159, 64, 0.6)';
            case 'wan': return 'rgba(153, 102, 255, 0.6)';
            case 'customLatency': return 'rgba(255, 99, 132, 0.6)';
            default: return 'rgba(201, 203, 207, 0.6)';
          }
        }),
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Latency Breakdown by Network Element',
      },
      // Add tooltip configuration to show both custom name and element name
      tooltip: {
        callbacks: {
          title: (context) => {
            const index = context[0].dataIndex;
            const element = elements[index];
            return element.customName ? `${element.customName} (${element.name})` : element.name;
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Latency (ns)',
        },
      },
    },
  };

  return <Bar data={data} options={options} />;
};

const StackedAreaChart = ({ elements }) => {
  const chartData = useMemo(() => ({
    labels: ['Start', ...elements.map((_, index) => `Step ${index + 1}`)],
    datasets: elements.map((element, index) => {
      const color = getUniqueColor(element.type, index);
      return {
        // Update this line to include both custom name and element name
        label: element.customName ? `${element.customName} (${element.name})` : element.name,
        data: [0, ...elements.map((_, i) => 
          i < index ? 0 : element.latency
        )],
        backgroundColor: createStripedPattern(color),
        borderColor: darken(0.1, color),
        borderWidth: 2,
        fill: true,
        pointRadius: 0, // Hide individual points
      };
    }),
  }), [elements]);

  const options = useMemo(() => ({
    responsive: true,
    scales: {
      x: { stacked: true },
      y: { 
        stacked: true,
        title: { display: true, text: 'Cumulative Latency (ns)' },
        beginAtZero: true
      }
    },
    plugins: {
      title: { display: true, text: 'Cumulative Latency Breakdown' },
      tooltip: {
        mode: 'index',
        intersect: false,
        callbacks: {
          afterTitle: (context) => {
            const dataIndex = context[0].dataIndex;
            if (dataIndex === 0) return 'Starting point: 0 ns';
            const cumulativeLatency = elements
              .slice(0, dataIndex)
              .reduce((sum, el) => sum + el.latency, 0);
            return `Total Latency: ${cumulativeLatency} ns`;
          },
          label: (context) => {
            const element = elements[context.datasetIndex];
            return `${element.customName || element.name}: ${element.latency} ns`;
          }
        }
      },
    },
    elements: {
      line: {
        tension: 0.3 // Add a slight curve to the lines
      }
    }
  }), [elements]);

  return <Line data={chartData} options={options} key={elements.length} />;
};

const baseColors = {
  switch: 'rgb(54, 162, 235)',
  fibre: 'rgb(75, 192, 192)',
  firewall: 'rgb(255, 159, 64)',
  wan: 'rgb(153, 102, 255)',
  customLatency: 'rgb(255, 99, 132)',
};

const getUniqueColor = (type, index, alpha = 0.8) => {
  const baseColor = baseColors[type] || 'rgb(201, 203, 207)';
  const lightness = Math.min(0.7, 0.3 + (index * 0.1));
  const color = lighten(lightness - 0.3, baseColor);
  return alpha < 1 ? color.replace('rgb', 'rgba').replace(')', `, ${alpha})`) : color;
};

const createStripedPattern = (color) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const size = 20;
  canvas.width = size;
  canvas.height = size;
  
  // Fill background
  ctx.fillStyle = lighten(0.1, color);
  ctx.fillRect(0, 0, size, size);
  
  // Draw stripes
  ctx.strokeStyle = darken(0.05, color);
  ctx.lineWidth = 2;
  ctx.beginPath();
  for (let i = 0; i < size * 2; i += 4) {
    ctx.moveTo(i, 0);
    ctx.lineTo(0, i);
  }
  ctx.stroke();
  
  return ctx.createPattern(canvas, 'repeat');
};

const SearchableDropdown = ({ options, onSelect, onCustomFibre, onCustomWAN, onCustomLatency }) => {
  const [search, setSearch] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [customInput, setCustomInput] = useState({ type: null, value: '', unit: 'ns' });
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const filteredOptions = options.filter(option => {
    const lowerCaseSearch = search.toLowerCase();
    return option.name.toLowerCase().includes(lowerCaseSearch) || 
           option.name.toLowerCase().includes('fibre') && lowerCaseSearch === 'fiber' ||
           option.name.toLowerCase().includes('fiber') && lowerCaseSearch === 'fibre';
  });

  const handleSelect = (option) => {
    setIsOpen(false);
    onSelect(option);
  };

  const handleCustomOption = (type) => {
    setCustomInput({ type, value: '', unit: type === 'customLatency' ? 'ns' : 'length' });
    setIsOpen(false);
  };

  const handleCustomSubmit = () => {
    if (customInput.value && !isNaN(customInput.value)) {
      const value = Number(customInput.value);
      
      if (customInput.type === 'fibre') {
        onCustomFibre(value); // Length in meters
      } else if (customInput.type === 'wan') {
        onCustomWAN(value); // Distance in kilometers
      } else if (customInput.type === 'customLatency') {
        let latencyInNs = value;
        switch (customInput.unit) {
          case 'ns':
            latencyInNs = value;
            break;
          case 'us':
            latencyInNs = value * 1000;
            break;
          case 'ms':
            latencyInNs = value * 1_000_000;
            break;
          default:
            latencyInNs = value;
        }
        onCustomLatency(latencyInNs); // Latency in nanoseconds
      }

      // Reset customInput
      setCustomInput({ type: null, value: '', unit: 'ns' });
    }
  };

  const getIcon = (type) => {
    switch (type) {
      case 'switch': return <Server size={18} className="text-blue-500" />;
      case 'fibre': return <Network size={18} className="text-green-500" />;
      case 'firewall': return <Shield size={18} className="text-orange-500" />;
      case 'wan': return <Globe size={18} className="text-purple-500" />;
      case 'customLatency': return <Edit2 size={18} className="text-red-500" />;
      default: return null;
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md border-2 border-blue-500">
      <h2 className="text-2xl font-bold mb-4 text-blue-700">Start Here: Add Network Elements</h2>
      <p className="mb-4 text-gray-600">Search and select elements to add to your network configuration.</p>
      <div className="relative" ref={dropdownRef}>
        <div className="flex items-center border-2 border-gray-300 rounded focus-within:border-blue-500">
          <input
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onClick={() => setIsOpen(true)}
            placeholder="Search elements (e.g., Switch, Firewall, Fibre)..."
            className="p-3 w-full rounded-l focus:outline-none"
          />
          <button onClick={() => setIsOpen(!isOpen)} className="p-3 bg-gray-100 rounded-r hover:bg-gray-200 transition-colors duration-200">
            <ChevronDown className={`transition-transform duration-200 ${isOpen ? 'transform rotate-180' : ''}`} />
          </button>
        </div>
        {isOpen && (
          <ul className="absolute z-10 w-full bg-white border rounded-b mt-1 max-h-60 overflow-auto shadow-lg">
            {filteredOptions.map((option, index) => (
              <li
                key={index}
                onClick={() => handleSelect(option)}
                className="p-2 hover:bg-gray-100 cursor-pointer flex items-center"
              >
                {getIcon(option.type)}
                <span className="ml-2">{option.name}</span>
              </li>
            ))}
            <li className="p-2 hover:bg-gray-100 cursor-pointer" onClick={() => handleCustomOption('fibre')}>
              Add Custom Fibre
            </li>
            <li className="p-2 hover:bg-gray-100 cursor-pointer" onClick={() => handleCustomOption('wan')}>
              Add Custom WAN
            </li>
            <li
              className="p-2 hover:bg-gray-100 cursor-pointer"
              onClick={() => handleCustomOption('customLatency')}
            >
              Add Custom Latency
            </li>
          </ul>
        )}
      </div>
      {customInput.type && (
        <div className="mt-4">
          <input
            type="number"
            value={customInput.value}
            onChange={(e) => setCustomInput({ ...customInput, value: e.target.value })}
            placeholder={
              customInput.type === 'fibre'
                ? 'Enter length in meters'
                : customInput.type === 'wan'
                ? 'Enter distance in kilometers'
                : 'Enter latency value'
            }
            className="w-full p-2 border rounded mb-2"
          />
          {customInput.type === 'customLatency' && (
            <select
              value={customInput.unit}
              onChange={(e) => setCustomInput({ ...customInput, unit: e.target.value })}
              className="w-full p-2 border rounded mb-2"
            >
              <option value="ns">Nanoseconds (ns)</option>
              <option value="us">Microseconds (µs)</option>
              <option value="ms">Milliseconds (ms)</option>
            </select>
          )}
          <button
            onClick={handleCustomSubmit}
            className="w-full bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300"
          >
            Add {customInput.type === 'fibre' ? 'Fibre' : customInput.type === 'wan' ? 'WAN' : 'Latency'}
          </button>
        </div>
      )}
    </div>
  );
};

const NetworkDiagram = ({ elements }) => {
  const getColor = (type) => {
    switch (type) {
      case 'switch': return 'bg-blue-500';
      case 'fibre': return 'bg-green-500';
      case 'firewall': return 'bg-orange-500';
      case 'wan': return 'bg-purple-500';
      case 'customLatency': return 'bg-red-500';
      default: return 'bg-gray-500';
    }
  };

  return (
    <div className="flex flex-col items-center space-y-2">
      {elements.map((el, index) => (
        <React.Fragment key={index}>
          <div className={`w-48 h-16 flex flex-col items-center justify-center rounded-lg ${getColor(el.type)} text-white text-center`}>
            <div className="font-semibold">{el.name}</div>
            {el.customName && <div className="text-xs">{el.customName}</div>}
            <div className="text-sm">{el.latency}ns</div>
          </div>
          {index < elements.length - 1 && (
            <div className="h-8 flex items-center justify-center">
              <div className="w-0.5 h-full bg-gray-400"></div>
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

const NetworkLatencyCalculator = () => {
  const [elements, setElements] = useState([]);
  const [networkElements, setNetworkElements] = useState([]);
  const [showInfo, setShowInfo] = useState(null);
  const [editingElement, setEditingElement] = useState(null);
  const [customDescription, setCustomDescription] = useState('');
  const [isChartExpanded, setIsChartExpanded] = useState(false);
  const [activeChart, setActiveChart] = useState('bar');
  const [showChartInfo, setShowChartInfo] = useState(false);

  useEffect(() => {
    const allElements = [
      ...networkData.switches.map(s => ({ ...s, type: 'switch', name: `${s.manufacturer} ${s.model}` })),
      ...networkData.fibres.map(f => ({ ...f, type: 'fibre', name: `Fibre ${f.length}m` })),
      ...networkData.firewalls.map(fw => ({ ...fw, type: 'firewall', name: `${fw.manufacturer} ${fw.model}` })),
      ...networkData.wans.map(w => ({ ...w, type: 'wan', name: w['WAN Name'], latency: w.latency }))
    ];
    setNetworkElements(allElements);
  }, []);

  const addElement = (selectedElement) => {
    if (selectedElement) {
      const newElement = { ...selectedElement, customName: '' };
      setElements(prevElements => [...prevElements, newElement]);
    }
  };

  const addCustomFibre = (lengthInMeters) => {
    const fibreLatency = calculateFibreLatency(lengthInMeters);
    const customFibre = {
      name: `Fibre (${lengthInMeters}m)`,
      type: 'fibre',
      latency: fibreLatency,
      details: `${lengthInMeters} meters of fibre, resulting in ${fibreLatency}ns latency.`,
    };
    setElements([...elements, customFibre]);
  };

  const addCustomWAN = (distanceInKm) => {
    const wanLatency = calculateWANLatency(distanceInKm);
    const customWAN = {
      name: `WAN (${distanceInKm}km)`,
      type: 'wan',
      latency: wanLatency,
      details: `${distanceInKm} kilometers of WAN, resulting in ${wanLatency}ns latency.`,
    };
    setElements([...elements, customWAN]);
  };

  const addCustomLatency = (latencyInNs) => {
    const customLatency = {
      name: 'Custom Latency',
      type: 'customLatency',
      latency: latencyInNs,
      details: `${latencyInNs} nanoseconds latency specified by user.`,
    };
    setElements([...elements, customLatency]);
  };

  const removeElement = (index) => {
    setElements(elements.filter((_, i) => i !== index));
  };

  const editDescription = (index) => {
    setEditingElement(elements[index]);
    setCustomDescription(elements[index].customName || '');
  };

  const saveDescription = () => {
    setElements(elements.map(el => 
      el === editingElement ? { ...el, customName: customDescription } : el
    ));
    setEditingElement(null);
    setCustomDescription('');
  };

  const totalLatency = elements.reduce((sum, el) => sum + el.latency, 0);

  const formatLatency = (ns) => {
    const us = ns / 1000;
    const ms = us / 1000;
    return {
      ns: ns.toFixed(0),
      us: us.toFixed(2),
      ms: ms.toFixed(2)
    };
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(elements);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setElements(items);
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="hero-section py-20 px-4 mb-8">
        <div className="container mx-auto relative">
          <div className="network-bg"></div>
          <h1 className="text-5xl font-bold text-center relative z-10 mb-4">
            Latency Lens
          </h1>
          <p className="text-2xl text-center relative z-10">
            Visualize and Calculate Network Latency
          </p>
        </div>
      </div>

      <div id="main-content">
        <div className="container mx-auto p-8">
          <div className="mb-8">
            <SearchableDropdown
              options={networkElements}
              onSelect={addElement}
              onCustomFibre={addCustomFibre}
              onCustomWAN={addCustomWAN}
              onCustomLatency={addCustomLatency}
            />
          </div>

          <div className="mb-8 bg-white rounded-lg shadow-md p-6">
            <h2 className="text-2xl font-semibold mb-4 text-gray-700">Network Elements</h2>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="elements">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {elements.map((el, index) => (
                      <Draggable key={index} draggableId={`element-${index}`} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className="flex items-center justify-between mb-4 bg-gray-50 p-3 rounded"
                          >
                            <div className="flex items-center">
                              <span {...provided.dragHandleProps} className="mr-2 cursor-move">
                                <GripVertical size={18} />
                              </span>
                              <span className="mr-2 font-medium">{el.name}</span>
                              {el.customName && <span className="text-sm text-gray-500">({el.customName})</span>}
                              <span className="ml-2 text-sm text-gray-500">({el.latency}ns)</span>
                            </div>
                            <div className="flex items-center">
                              <button onClick={() => editDescription(index)} className="mr-2 text-blue-500 hover:text-blue-700">
                                <Edit2 size={18} />
                              </button>
                              <button onClick={() => setShowInfo(el)} className="mr-2 text-blue-500 hover:text-blue-700">
                                <Info size={18} />
                              </button>
                              <button onClick={() => removeElement(index)} className="text-red-500 hover:text-red-700">
                                <Trash2 size={18} />
                              </button>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>

          {showInfo && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white p-6 rounded-lg shadow-xl max-w-md">
                <h2 className="text-2xl font-bold mb-4">{showInfo.name}</h2>
                {showInfo.type === 'switch' ? (
                  <>
                    <p><strong>Manufacturer:</strong> {showInfo.manufacturer}</p>
                    <p><strong>Model:</strong> {showInfo.model}</p>
                    <p><strong>Latency:</strong> {showInfo.latency}ns</p>
                    <p><strong>Caveats:</strong> {showInfo.caveats}</p>
                    <p><strong>Documentation:</strong> <a href={showInfo.documentation} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">Link</a></p>
                  </>
                ) : showInfo.type === 'fibre' ? (
                  <>
                    <p><strong>Type:</strong> Fibre</p>
                    <p><strong>Length:</strong> {showInfo.length}m</p>
                    <p><strong>Latency:</strong> {showInfo.latency}ns</p>
                    <p><strong>Details:</strong> {showInfo.details}</p>
                  </>
                ) : showInfo.type === 'firewall' ? (
                  <>
                    <p><strong>Manufacturer:</strong> {showInfo.manufacturer}</p>
                    <p><strong>Model:</strong> {showInfo.model}</p>
                    <p><strong>Latency:</strong> {showInfo.latency}ns</p>
                    <p><strong>Caveats:</strong> {showInfo.caveats}</p>
                    <p><strong>Documentation:</strong> <a href={showInfo.documentation} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">Link</a></p>
                  </>
                ) : showInfo.type === 'wan' ? (
                  <>
                    <p><strong>WAN Provider:</strong> {showInfo['WAN Provider']}</p>
                    <p><strong>WAN Name:</strong> {showInfo['WAN Name']}</p>
                    <p><strong>Latency:</strong> {showInfo.latency}ns</p>
                    <p><strong>Caveats:</strong> {showInfo.caveats}</p>
                    <p><strong>Documentation:</strong> <a href={showInfo.documentation} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">Link</a></p>
                  </>
                ) : showInfo.type === 'customLatency' ? (
                  <>
                    <p><strong>Type:</strong> Custom Latency</p>
                    <p><strong>Latency:</strong> {showInfo.latency}ns</p>
                    <p><strong>Details:</strong> {showInfo.details}</p>
                  </>
                ) : null}
                <button onClick={() => setShowInfo(null)} className="mt-6 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300">Close</button>
              </div>
            </div>
          )}

          {editingElement && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white p-6 rounded-lg shadow-xl max-w-md">
                <h2 className="text-2xl font-bold mb-4">Edit Description</h2>
                <input
                  type="text"
                  value={customDescription}
                  onChange={(e) => setCustomDescription(e.target.value)}
                  placeholder="Enter custom description"
                  className="w-full p-2 border rounded mb-4"
                />
                <div className="flex justify-end">
                  <button onClick={() => setEditingElement(null)} className="mr-2 px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400 transition duration-300">Cancel</button>
                  <button onClick={saveDescription} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300">Save</button>
                </div>
              </div>
            </div>
          )}

          <div className="mb-8 p-6 bg-white rounded-lg shadow-md">
            <h2 className="text-2xl font-semibold mb-4 text-gray-700">Latency Breakdown</h2>
            <div className="mb-4 flex items-center">
              <button
                onClick={() => setActiveChart('bar')}
                className={`mr-2 px-4 py-2 rounded ${activeChart === 'bar' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
              >
                Bar Chart
              </button>
              <button
                onClick={() => setActiveChart('area')}
                className={`px-4 py-2 rounded ${activeChart === 'area' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
              >
                Stacked Area Chart
              </button>
              <Info 
                size={18} 
                className="ml-2 text-gray-500 cursor-pointer" 
                onClick={() => setShowChartInfo(true)}
              />
            </div>

            {activeChart === 'bar' ? (
              <LatencyBreakdownChart elements={elements} />
            ) : (
              <StackedAreaChart elements={elements} />
            )}

            {showChartInfo && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                <div className="bg-white p-6 rounded-lg shadow-xl max-w-md">
                  <h3 className="text-xl font-bold mb-2">Chart Types</h3>
                  <p className="mb-2"><strong>Bar Chart:</strong> Shows individual latency contributions of each network element.</p>
                  <p className="mb-4"><strong>Stacked Area Chart:</strong> Displays cumulative latency, showing how latency builds up across the network path.</p>
                  <button 
                    onClick={() => setShowChartInfo(false)}
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                  >
                    Close
                  </button>
                </div>
              </div>
            )}

            <div className="overflow-x-auto">
              <table className="w-full text-left">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="p-2">Component</th>
                    <th className="p-2">Description</th>
                    <th className="p-2">Type</th>
                    <th className="p-2">Latency (ns)</th>
                    <th className="p-2">Running Total (ns)</th>
                    <th className="p-2">% of Total</th>
                  </tr>
                </thead>
                <tbody>
                  {elements.map((el, index) => {
                    const runningTotal = elements.slice(0, index + 1).reduce((sum, e) => sum + e.latency, 0);
                    const percentage = ((el.latency / totalLatency) * 100).toFixed(2);
                    return (
                      <tr key={index} className="border-b">
                        <td className="p-2">{el.name}</td>
                        <td className="p-2">{el.customName || '-'}</td>
                        <td className="p-2">{el.type}</td>
                        <td className="p-2">{el.latency}</td>
                        <td className="p-2">{runningTotal}</td>
                        <td className="p-2">{percentage}%</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <h2 className="text-2xl font-semibold mt-6 mb-2 text-gray-700">Total Latency</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {Object.entries(formatLatency(totalLatency)).map(([unit, value]) => (
                <div key={unit} className="bg-gray-100 p-4 rounded-lg">
                  <p className="text-lg font-semibold">{value} {unit}</p>
                  <p className="text-sm text-gray-600">{unit === 'ns' ? 'nanoseconds' : unit === 'us' ? 'microseconds' : 'milliseconds'}</p>
                </div>
              ))}
            </div>
          </div>

          <div className="mt-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-700 text-center">Network Diagram</h2>
            <div className="border p-6 rounded-lg bg-white shadow-md overflow-x-auto">
              <div className="flex justify-center">
                <NetworkDiagram elements={elements} />
              </div>
            </div>
          </div>

          <div className="mt-12 text-center">
            <Link to="/about" className="text-blue-500 hover:text-blue-700 transition-colors duration-300 mr-4">
              About & FAQs
            </Link>
          </div>

          <div className="mt-8 text-center text-gray-500 text-sm">
            © 2024 Latency Lens. All rights reserved.
          </div>
        </div>
      </div>

      <div className="fixed bottom-4 right-4">
        <Link 
          to="/about" 
          className="bg-blue-500 text-white px-4 py-2 rounded-full shadow-lg hover:bg-blue-600 transition-colors duration-300"
          onClick={() => window.scrollTo(0, 0)}
        >
          About & FAQs
        </Link>
      </div>
    </div>
  );
};

export default NetworkLatencyCalculator;